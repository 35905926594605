import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Contact from "../../components/sections/Contact"
import Services from '../../components/sections/Services'

const SeniorPhpDeveloper = () => {
  return (
    <>
    <SEO title="vacancies"/>
    <Layout>
        <article className='co-page'>
                <section className="typical__padding co-bullet">
                    <div className="section-container">
                        <h2 className="section-title">Senior PHP Developer</h2>
                        <p className="co-bullet-info">
                            We are a team of payment technologies seeking passionate professionals to reach new heights. DigiMeth is currently in search of a <b>Senior PHP developer.</b>
                        </p>
                        <div className="co-vacancies--grid">
                            <h3 className='co-bullet-title'>About DigiMeth</h3>
                            <p className="co-bullet-info">
                                Digital Method is a software development company specialising in payment technology. Our mission is to provide fast, high-quality and diverse online payment experience to our partners and their customers. We provide international and local payments for all business types.
                            </p>
                            <h3 className='co-bullet-title'>What you`ll be doing</h3>
                            <ul className='co-bullet-list'>
                                <li>Developing from scratch or reusing existing components and integrating them into new solutions.</li>
                                <li>Developing custom integration solutions between our client's system and third-party software.</li>
                                <li>Participating in estimation and planning sessions.</li>
                                <li>Coding, debugging, documenting, implementing, and maintaining software.</li>
                                <li>Conducting code reviews to ensure adherence to best practices, coding standards, and project-specific quality criteria.</li>
                                <li>Responding to identified issues and resolving them.</li>
                            </ul>

                            <h3 className='co-bullet-title'>What you`ll need</h3>
                            <ul className='co-bullet-list'>
                                <li>Commercial PHP development experience of 4+ years.</li>
                                <li>Experience working with MySQL/PostgreSQL.</li>
                                <li>Experience working with Symfony - highly desired.</li>
                                <li>Experience in writing asynchronous applications/modules.</li>
                                <li>Experience integrating with other products and APIs.</li>
                                <li>Experience working with RESTful web services, SOAP web services, PostgreSQL/MySQL.</li>
                                <li>Strong understanding of cloud-based architecture, distributed architecture, integration patterns, web-oriented programming, multithreading, and queue processing.</li>
                                <li>Understanding of modern front-end development frameworks.</li>
                                <li>Experience with Doctrine, HTML, CSS, JavaScript - desired.</li>
                                <li>Experience working with Agile.</li>
                                <li>English language level - technical, at least Pre-Intermediate.</li>
                            </ul>

                            <h3 className='co-bullet-title'>Nice to have</h3>
                            <ul className='co-bullet-list'>
                                <li>Experience working with other frameworks</li>
                                <li>Management skills and experience leading technical teams</li>
                                <li>Experience in e-commerce, fintech companies, or the banking sector</li>
                            </ul>

                            <p className="co-bullet-info">Work schedule - 5/2, 09:00-18:00 (aligned with banking system operations).</p>

                            <div className='co-join-container'>
                                <span>If you are interested in working with us, send your CV here</span>{' '}
                                <a href="mailto:contact@digimeth.net">contact@digimeth.net</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Services title="Work at Digimeth Feels Perfect!" desc="Our goal is to create fantastic payment solutions:" />
                <Contact/>
            </article>
        </Layout>
    </>
  )
}

export default SeniorPhpDeveloper